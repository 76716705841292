import { ButtonGroup, Col, Container, PageBanner, Pagination, Row, SearchInput, SocialButton, Text, Thumbnail, Title, usePagination, useSearch } from '@zigurous/react-components'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageProps, Page } from '../components';
import { trackEvent } from '../firebase/analytics';

export const query = graphql`
  query Assets {
    json: allAssetsJson {
      assets: nodes {
        id: jsonId
        title
        subtitle
        searchQuery
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;

const metadata = {
  url: 'https://zigurous.com/assets',
  title: 'Zigurous • Assets',
  description:
    'Zigurous creates flexible, customizable Unity assets to help new and experienced developers make games easier.',
};

function searchQuery(item, query) {
  const search = item.searchQuery || item.title.toLowerCase().replace(' ', '');
  return search.includes(query);
}

function Assets({ data, location }) {
  const { assets } = data.json;
  const [pagination, setPage] = usePagination(assets, 6, 'assets-page');
  const [search] = useSearch(assets, searchQuery, 'assets-search');
  return (
    <Page className="assets" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col xl={10}>
              <Title subtitle size={5}>
                Free and Premium
              </Title>
              <Title nowrap tag="h1">
                Game Assets
              </Title>
              <Text size="lg">
                We create flexible, customizable Unity assets to help new and
                experienced developers make games easier. These assets comprise
                a larger suite of tools that allow developers to create many
                kinds of games. Our assets can be used in both commercial and
                non-commercial projects.
              </Text>
              <ButtonGroup>
                <SocialButton
                  link="unity"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Asset Store',
                    });
                  }}
                  shape={SocialButton.shape.rounded}
                  style={SocialButton.style.outline}
                >
                  Asset Store
                </SocialButton>
                <SocialButton
                  link="github"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Open Source',
                    });
                  }}
                  shape={SocialButton.shape.rounded}
                  style={SocialButton.style.outline}
                >
                  Open Source
                </SocialButton>
              </ButtonGroup>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        <Row>
          <Col className="margin-bottom-xl" md={6} lg={4}>
            <SearchInput
              {...search}
              aria-label="Search for assets"
              onSearch={(query) => {
                search.onSearch(query);
                if (query) {
                  trackEvent('search', {
                    content_type: 'input',
                    event_category: 'engagement',
                    event_label: 'Search for assets',
                    search_term: query,
                  });
                }
              }}
            />
          </Col>
          {pagination.paginated && (
            <Col
              className="display-flex flex-row-reverse flex-row-md justify-content-flex-end margin-bottom-xl"
              md={6}
              lg={8}
            >
              <Pagination {...pagination} onPageChange={setPage} />
            </Col>
          )}
        </Row>
        <Row>
          {(search.results || pagination.items).map((asset, index) => (
            <Col className="padding-lg" key={asset.id} md={6} lg={4}>
              <Thumbnail
                alt={asset.title}
                animated
                aria-label={asset.title}
                ElementType={GatsbyLink}
                height={asset.image.sharp.original.height}
                image={asset.image.sharp.original.src}
                index={index}
                onClick={() => {
                  if (typeof sessionStorage !== 'undefined') {
                    sessionStorage.setItem('assets-page', pagination.page);
                    sessionStorage.setItem('assets-search', search.query);
                  }
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: asset.title,
                    item_id: asset.id,
                  });
                }}
                to={`/assets/${asset.id}`}
                width={asset.image.sharp.original.width}
              />
              <Title bold marginTop="md">
                {asset.title}
              </Title>
              <Title eyebrow>{asset.subtitle}</Title>
            </Col>
          ))}
          {search.results && search.results.length === 0 && (
            <Col>
              <Title
                color="foreground-muted"
                marginTop="md"
                size={6}
                weight={500}
              >
                No assets were found matching that search.
              </Title>
            </Col>
          )}
        </Row>
      </Container>
    </Page>
  );
}

Assets.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      assets: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          searchQuery: PropTypes.string,
          image: ImageProps,
        })
      ),
    }),
  }),
  location: PropTypes.object,
};

export default Assets;
